import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ParagraphText,
  SectionTitleText,
} from '../../StyledComponents/Typography';
import {
  BaseTableRow,
  SelectedTableHeaderRow,
  SelectedTableRow,
} from '../../StyledComponents/Table';
import styled from 'styled-components';
import { StyledButton, withTheme } from '@canopysecurity/component-library';
import {
  Artifact,
  DeploymentAttempt,
  useGetDeploymentQuery,
} from '../../gql/generated';

const DataBarContainer = styled.div`
  display: grid;
  height: fit-content;
`;

const StyledColumn = styled.div`
  grid-column: span 2;
  grid-row: 1;
`;

const KeyCell = styled.div`
  font-size: 12px;
  font-weight: bolder;
`;

const StyledKeyCell = withTheme(styled(KeyCell)`
  border-bottom: 1px solid ${({ theme }) => theme.text.main};
`);

const ValueCell = styled.div`
  font-size: 16px;
  font-weight: normal;
`;

const SectionTitle = ({ title }: { title: string }): JSX.Element => {
  return (
    <div style={{ marginTop: '3%', marginBottom: '0.5%' }}>
      <SectionTitleText>{title}</SectionTitleText>
    </div>
  );
};

const DataBar = ({ data }: { data: any }): JSX.Element => {
  return (
    <DataBarContainer>
      {Object.keys(data).map((key) => {
        return (
          <StyledColumn key={key}>
            <StyledKeyCell>{key}</StyledKeyCell>
            <ValueCell>{data[key]}</ValueCell>
          </StyledColumn>
        );
      })}
    </DataBarContainer>
  );
};

interface TableRowProps {
  shadowedBackground: boolean;
}

const IncludedArtifactsTable = withTheme(styled(BaseTableRow)`
  gap: 0;
  margin-top: unset;
  padding: 0 10px 10px 10px;
  grid-template-columns: 33fr 33fr 34fr;
  background-color: ${({ theme }) => theme.background.main};
  border: 1px solid ${({ theme }) => theme.text.main};
  margin-top: 20px;
  > * {
    border-bottom: 1px solid ${({ theme }) => theme.text.main};
  }
`);
const DeploymentAttemptsTable = withTheme(styled(BaseTableRow)`
  gap: 0;
  margin-top: unset;
  padding: 0 10px 10px 10px;
  grid-template-columns: 33fr 33fr 34fr;
  background-color: ${({ theme }) => theme.background.main};
  border: 1px solid ${({ theme }) => theme.text.main};
  margin-top: 20px;
  > * {
    border-bottom: 1px solid ${({ theme }) => theme.text.main};
  }
`);
const IncludedArtifactsTableRow = styled(SelectedTableRow)<TableRowProps>`
  ${({ shadowedBackground }) =>
    shadowedBackground &&
    `
    background-color: #00000000;
  `}
`;

const TableHeader = styled.span`
  font-size: 12px;
  font-weight: bolder;
`;

const TextLine = styled(ParagraphText)`
  display: block;
`;

export const DeploymentDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetDeploymentQuery({ variables: { id: id ?? '' } });

  const date = new Date(data?.getDeployment?.createTime ?? Date.now());
  const attemptsRaw = (data?.getDeployment?.attempts ?? []).filter(
    Boolean,
  ) as DeploymentAttempt[];

  const attempts = attemptsRaw
    ? attemptsRaw.map((attempt: DeploymentAttempt) => {
        return {
          startTime: attempt.startTime,
          deployedBy: attempt.deployedBy ?? 'unknown',
          tags: attempt.tags ?? [],
        };
      })
    : [];

  const artifacts = useMemo(
    () =>
      (data?.getDeployment?.release?.artifacts ?? []).filter(
        (a): a is Artifact => !!a,
      ),
    [data?.getDeployment?.release?.artifacts],
  );

  return (
    <>
      <SectionTitle title={'Update Details'} />
      <ParagraphText>
        Details about the deployed update. To view status of the deployment or
        to stop the deployment, please go to the AWS site.
      </ParagraphText>
      <SectionTitle title="Overview" />
      <DataBar
        data={
          data
            ? {
                'RELEASE VERSION': data.getDeployment?.release?.semver,
                'RELEASE DATE': date.toLocaleDateString(),
                'JOB ID': data.getDeployment?.jobId ?? '-',
                'RELEASE TIME': date.toTimeString(),
                'DEVICE TYPE': data.getDeployment?.release?.deviceType,
              }
            : {}
        }
      />
      <SectionTitle title="Status of Things" />
      <DataBar
        data={
          data
            ? {
                QUEUED: data.getDeployment?.iotJob?.numberOfQueuedThings,
                'IN PROGRESS':
                  data.getDeployment?.iotJob?.numberOfInProgressThings,
                SUCCEEDED: data.getDeployment?.iotJob?.numberOfSucceededThings,
                FAILED: data.getDeployment?.iotJob?.numberOfFailedThings,
                CANCELLED: data.getDeployment?.iotJob?.numberOfCanceledThings,
                REJECTED: data.getDeployment?.iotJob?.numberOfRejectedThings,
                REMOVED: data.getDeployment?.iotJob?.numberOfRemovedThings,
                'TIMED OUT': data.getDeployment?.iotJob?.numberOfTimedOutThings,
              }
            : {}
        }
      />
      <SectionTitle title="Artifacts Included" />
      <IncludedArtifactsTable>
        <SelectedTableHeaderRow>
          <TableHeader>ARTIFACT CATEGORY</TableHeader>
          <TableHeader>ARTIFACT NAME</TableHeader>
          <TableHeader>DEPENDENT ON</TableHeader>
        </SelectedTableHeaderRow>
        {artifacts.map((artifact, index: number) => {
          return (
            <IncludedArtifactsTableRow
              key={artifact.typeName}
              shadowedBackground={index % 2 === 1}
            >
              <ParagraphText>{artifact.typeName}</ParagraphText>
              <ParagraphText>
                {artifact.typeName}-{artifact.semver}
              </ParagraphText>
              <ParagraphText>
                {artifact.dependencies
                  ?.map((dependency) => {
                    return `${dependency?.typeName}-${dependency?.semver}`;
                  })
                  .join(', ')}
              </ParagraphText>
            </IncludedArtifactsTableRow>
          );
        }) || <></>}
      </IncludedArtifactsTable>

      <SectionTitle title="Deployment Attempts" />
      <DeploymentAttemptsTable>
        <SelectedTableHeaderRow>
          <TableHeader>DEPLOYMENT STARTED</TableHeader>
          <TableHeader>DEPLOYED BY</TableHeader>
          <TableHeader>DEPLOYED TO</TableHeader>
        </SelectedTableHeaderRow>
        {attempts?.map((deploymentAttempt, index: number) => {
          return (
            <IncludedArtifactsTableRow
              key={deploymentAttempt.startTime}
              shadowedBackground={index % 2 === 1}
            >
              <ParagraphText>{deploymentAttempt.startTime}</ParagraphText>
              <ParagraphText>{deploymentAttempt.deployedBy}</ParagraphText>
              <ParagraphText>{deploymentAttempt.tags}</ParagraphText>
            </IncludedArtifactsTableRow>
          );
        }) || <></>}
      </DeploymentAttemptsTable>
      <div style={{ marginTop: '3%', display: 'grid' }}>
        <TextLine>
          Redeploy this Release to a new enviornment and/or new device groups.
        </TextLine>
        <TextLine>
          Release Version, Device Type, and Artifacts Included will remain the
          same.
        </TextLine>
      </div>
      <StyledButton
        onPress={() => {
          navigate('/deploy-release-package', {
            state: data?.getDeployment?.release,
          });
        }}
        disabled={false}
        style={{
          height: '60px',
          width: '24%',
          marginTop: '2%',
          marginRight: '2%',
        }}
      >
        Redeploy Release
      </StyledButton>
    </>
  );
};
